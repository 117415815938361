import { storeToRefs } from 'pinia';
import { useSumStore } from './sum.store';

import type { TPossibleNull } from '~/types/Shared.types';
import { EPromoTypes, type IBloggerOffer, type TPromo } from '~/features/payment/types/promo/client.types';
import type { IPaymentApplyInstPromoBody } from '~/repository/modules/payment/PaymentQueries.types';
import { useUserStore } from '~/store/user/user.store';
import { useAlertStore } from '~/store/alert/alert.store';
import { PROMO_INPUT_DEBOUNCE } from '~/features/payment/constants/times.constants';

interface IBloggerRewardsItem {
  bonus: IBloggerOffer;
  isActive: boolean;
  isDisabled: boolean;
}

export const usePromoStore = defineStore('payment/promo', () => {
  const {
    $api: { payment: PaymentService },
  } = useNuxtApp();

  /* -- Stores -- */
  /* Стор суммы */
  const sumStore = useSumStore();
  const { sum } = storeToRefs(sumStore);

  /* -- Const -- */
  const promoInfo = reactive({
    error: '',
    input: '',
    isSucceed: false,
  });
  /* Текущий промокод */
  const currentPromo = ref<TPossibleNull<TPromo>>(null);
  const debounce = ref<ReturnType<typeof setTimeout>>();

  /* -- Getters -- */
  /* Обычный промокод ( с процентом ) */
  const commonPromo = computed(() => {
    if (currentPromo.value?.type === EPromoTypes.COMMON) return currentPromo.value.percent;
    return 0;
  });
  /* Блоггерский промокод ( с бонусами ) */
  const bloggerPromo = computed(() => {
    if (currentPromo.value?.type === EPromoTypes.BLOGGER) return currentPromo.value.offers;
    return null;
  });
  /* Мгновенный промокод */
  const instantPromo = computed(() => {
    if (currentPromo.value?.type === EPromoTypes.INSTANT) return currentPromo.value.sum;
    return 0;
  });

  /* Активный блоггерский бонус ( на основе суммы ) */
  const activeBloggerBonus = computed(() => {
    const result = bloggerPromo.value?.findLast((bonus) => bonus.from <= sum.value);
    return result ?? null;
  });
  /* Блоггерские бонусы для вида экрана оплаты скинами */
  const skinsBloggerBonuses = computed(() => {
    return bloggerPromo.value?.map((bonus, index) => ({
      bonus,
      level: index + 1,
    }));
  });
  /* Получаем блоггерские бонусы для карточек-наград */
  const bloggerRewards = computed(() => {
    if (!bloggerPromo.value) return [];

    const result: IBloggerRewardsItem[] = [];
    const bonusesLength = bloggerPromo.value.length;

    for (let index = 0; index < bonusesLength; index++) {
      const bonus = bloggerPromo.value[index];
      const sumLargerThanBonus = bonus.from <= sum.value;
      if (sumLargerThanBonus && index < bonusesLength - 2) continue;

      result.push({
        bonus,
        isActive: index === bonusesLength - 1 && sumLargerThanBonus,
        isDisabled: index === bonusesLength - 2 && sumLargerThanBonus,
      });

      if (result.length === 2) break;
    }

    return result;
  });

  /* -- Methods -- */
  /* Устанавливаем значение для стейта промокода */
  const setPromoInfo = (promo: TPossibleNull<TPromo>, isSucceed: boolean, error: string) => {
    currentPromo.value = promo;
    promoInfo.isSucceed = isSucceed;
    promoInfo.error = error;
  };

  /* Получаем промокод */
  const getPromo = async (promo: string) => {
    if (!promo) {
      setPromoInfo(null, false, '');
      return;
    }

    try {
      const promoResponse = await PaymentService.getPromo(promo);

      if ('message' in promoResponse) {
        setPromoInfo(null, false, promoResponse.message);
        return;
      }

      setPromoInfo(promoResponse, true, '');
    } catch {
      setPromoInfo(null, false, 'Указанный код не найден');
    }
  };

  watch(
    () => promoInfo.input,
    async (newValue) => {
      if (debounce.value) {
        clearTimeout(debounce.value);
      }

      if (!newValue) {
        await getPromo(newValue);
        return;
      }

      debounce.value = setTimeout(async () => {
        await getPromo(newValue);
      }, PROMO_INPUT_DEBOUNCE);
    },
  );

  const applyInstantPromo = async () => {
    if (currentPromo.value?.type !== EPromoTypes.INSTANT) {
      return;
    }
    const payload: IPaymentApplyInstPromoBody = {
      userId: useUserStore().userId ?? 0,
      utmContent: promoInfo.input,
    };

    try {
      const response = await PaymentService.applyInstantPromo(payload);
      const { success, message } = response;

      if (!success) {
        useAlertStore().showError({
          message,
          title: 'Ошибка при применении промокода',
        });
      }

      return success;
    } catch {}
  };

  return {
    activeBloggerBonus,
    applyInstantPromo,
    bloggerPromo,
    bloggerRewards,
    commonPromo,
    currentPromo,
    getPromo,
    instantPromo,
    promoInfo,
    skinsBloggerBonuses,
  };
});
