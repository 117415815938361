import type { TPossibleNull } from '~/types/Shared.types';
import type { TValidationFunction } from '~/features/payment/types/country/client.types';

interface IFieldState {
  error: string;
  required: boolean;
  value: TPossibleNull<string>;
}

export function useField(required = false, validator?: TValidationFunction) {
  const state = reactive<IFieldState>({
    error: '',
    required,
    value: null,
  });

  watch(
    () => state.value,
    (newValue) => {
      if (required && !newValue) {
        state.error = 'Заполните поле';
        return;
      }

      if (validator) {
        const { masked, valid } = validator(`${newValue ?? ''}`);

        if (!valid) {
          state.error = 'Введите правильное значение';
          return;
        }

        state.value = masked;
      }

      state.error = '';
    },
    {
      deep: true,
    },
  );

  return state;
}
