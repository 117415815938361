import { storeToRefs } from 'pinia';

import { useMethodsStore } from './methods.store';
import { useCurrencyStore } from './currency.store';
import { Currency } from '~/constants/currency.constants';
import type { TCurrencyType } from '~/types/Shared.types';

export const useSumStore = defineStore('payment/sum', () => {
  const {
    $projectConfig: { defaultCurrency },
  } = useNuxtApp();

  /* -- Stores -- */
  /* Стор методов оплаты */
  const methodsStore = useMethodsStore();
  const { selectedMethod } = storeToRefs(methodsStore);
  /* Стор валюты страны */
  const currencyStore = useCurrencyStore();
  const { currency } = storeToRefs(currencyStore);

  /* -- Const -- */
  const sumField = reactive({
    error: '',
    value: '',
  });
  const isAgreementChecked = ref(true);

  /* -- Getters -- */
  /* Сумма в виде числа */
  const sum = computed(() => {
    const val = +sumField.value;
    return Number.isNaN(val) ? 0 : val;
  });

  const sumWithRate = computed(() => {
    const rate = currency.value?.rate ?? 1;
    return sum.value / rate;
  });

  /* -- Watchers -- */
  /* При изменении валюты, устанавливать дефолтную сумму */
  watch(currency, (value) => {
    if (!value?.default) return;
    sumField.value = `${value.default}`;
  });
  /* При изменении метода оплаты или суммы, проводить валидацию поля с суммой */
  watch(
    [selectedMethod, () => sumField.value],
    ([method, value]) => {
      if (Number.isNaN(+value)) {
        sumField.error = 'Введенная сумма не является числом';
        return;
      }

      if (method) {
        const minValue = method.min ?? 0;
        const currencySymbol = currency.value?.symbol ?? Currency[defaultCurrency as TCurrencyType];

        sumField.error = sum.value < minValue ? `Минимальная сумма пополнения: ${minValue} ${currencySymbol}` : '';
        return;
      }

      sumField.error = '';
    },
    {
      deep: true,
    },
  );

  return {
    isAgreementChecked,
    sum,
    sumField,
    sumWithRate,
  };
});
