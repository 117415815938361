import { storeToRefs } from 'pinia';
import type { IAdditionalInfo, IPaymentStartPostBody } from '~/repository/modules/payment/PaymentQueries.types';
import { useCountryStore } from '~/features/payment/store/country.store';
import { useSumStore } from '~/features/payment/store/sum.store';
import { useCurrencyStore } from '~/features/payment/store/currency.store';
import { useMethodsStore } from '~/features/payment/store/methods.store';
import { ECountryMethodType } from '~/features/payment/types/country/client.types';
import { usePromoStore } from '~/features/payment/store/promo.store';
import { useAdditionalFieldsStore } from '~/features/payment/store/additionalFields.store';
import type { TPossibleNull } from '~/types/Shared.types';
import { ErrorCodes } from '~/api/global/errors/codes/codes';
import { useAlertStore } from '~/store/alert/alert.store';

export const useTransactionStore = defineStore('payment/transaction', () => {
  const {
    $api: { payment: PaymentService },
  } = useNuxtApp();

  const redirectUrl = ref<TPossibleNull<string>>();
  const orderId = ref<TPossibleNull<string>>(null);
  const countryStore = useCountryStore();
  const { country } = storeToRefs(countryStore);

  const sumStore = useSumStore();
  const { sum } = storeToRefs(sumStore);

  const currencyStore = useCurrencyStore();
  const { currency } = storeToRefs(currencyStore);

  const computedSum = computed(() => {
    const rate = currency.value?.rate ?? 1;
    return sum.value * rate;
  });

  const methodsStore = useMethodsStore();
  const { selectedMethod } = storeToRefs(methodsStore);

  const promocodeStore = usePromoStore();
  const { promoInfo } = storeToRefs(promocodeStore);

  const additionalFieldsStore = useAdditionalFieldsStore();
  const { fields } = storeToRefs(additionalFieldsStore);

  const alertStore = useAlertStore();

  const isLoading = ref(false);

  const promoCode = computed(() => {
    if (!promoInfo.value) {
      return null;
    }
    if (!promoInfo.value.isSucceed) {
      return null;
    }
    return promoInfo.value.input;
  });

  const additionalInfo = computed<Partial<IAdditionalInfo> | null>(() => {
    if (Object.keys(fields.value).length === 0) {
      return null;
    }

    // Доказываю тайпскрипту, что я хитрее
    const result: Record<string, unknown> = {};
    for (const field of Object.entries(fields.value)) {
      const fieldName = field[0];
      const fieldValue = field[1].value;
      result[fieldName] = fieldValue;
    }

    return result;
  });

  const payload = computed<IPaymentStartPostBody>(() => {
    const payload: IPaymentStartPostBody = {
      countryIso: country.value?.country.iso,
      payMethod: selectedMethod.value?.payMethod ?? '',
      paymentDirectionId: selectedMethod.value?.id ?? 0,
      paymentType: selectedMethod.value?.type === ECountryMethodType.COMMON ? '1' : '2',
      sum: computedSum.value,
    };

    if (promoCode.value) {
      payload.promo = promoCode.value;
    }

    if (additionalInfo.value) {
      payload.additionalInfo = additionalInfo.value;
    }

    return payload;
  });
  const startTransaction = async () => {
    try {
      isLoading.value = true;
      const result = await PaymentService.startPayment(payload.value);
      redirectUrl.value = result.url;
      orderId.value = result.orderId;
      localStorage.setItem('lastOrderId', orderId.value);
    } catch (e) {
      alertStore.showError({
        message: (e as { msg: string | undefined }).msg || '',
        title: ErrorCodes.UNPREDICTED_EXCEPTION,
      });
    } finally {
      isLoading.value = false;
    }
  };

  return {
    isLoading,
    orderId,
    redirectUrl,
    startTransaction,
  };
});
